import {get, post} from "aws-amplify/api";
import {PulseViewModel} from "../viewModel/PulseView.model";
import {Responses} from "../type/StandardResponse";
import {ReportedContentAddBean} from "../beanModels/ReportedContentAddBean.model";


export namespace PulseServices {
    export async function findPulseListForEvent(eventId: number): Promise<PulseViewModel[]> {
        const restOperation = get({
            apiName: 'API',
            path: `/pulses/byEvent/${eventId}`
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<PulseViewModel[]>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        return result.body;
    }

    export async function reportPulseForContentViolation(bean: ReportedContentAddBean.Model): Promise<number> {
        const restOperation = post({
            apiName: 'API',
            path: `/pulses/report-content`,
            options: {body: ReportedContentAddBean.toDocumentType(bean)}
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<number>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        return result.body;
    }
}

