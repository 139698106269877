import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {logOutOutline} from "ionicons/icons";
import React from "react";
import {UserStore} from "../../store/UserStore";
import {useHistory} from "react-router-dom";

export function SignOut() {
    const history = useHistory();
    const handleSignOut = () => {
        const signOutHook = UserStore.getRawState().signOutHook;
        if (signOutHook) {
            signOutHook();
        }
        history.push('/myevents');
    };

    return (
        <IonItem button={true} onClick={handleSignOut}>
            <IonIcon color="tertiary" slot="start" icon={logOutOutline}></IonIcon>
            <IonLabel>Sign Out</IonLabel>
        </IonItem>
    )
}
