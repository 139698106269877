import {PulseViewModel} from "../../viewModel/PulseView.model";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonModal, IonTitle, IonToolbar} from "@ionic/react";
import React, {MouseEvent, useState} from "react";

import * as ImageHelper from '../../utilities/ImageHelper';
import {close} from "ionicons/icons";
import {IonModalCustomEvent} from "@ionic/core/dist/types/components";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import './PulseImageView.scss';

export interface PulseImageViewProps{
    pulse: PulseViewModel
}

export function PulseImageView({pulse}: PulseImageViewProps) {
    const [showFull, setShowFull] = useState(false);

    const handleShow = (event: MouseEvent<HTMLIonImgElement>) => {
        event.stopPropagation();
        setShowFull(true);
    }

    const handleClose = (event: MouseEvent<HTMLIonButtonElement> | IonModalCustomEvent<OverlayEventDetail>) => {
        event.stopPropagation();
        setShowFull(false);
    }

    return (
        <>
            <div>
                {pulse.message}
            </div>
            <div className="ion-align-items-center event-pulse-pulse-image-container">
            <IonImg className={"event-pulse-pulse-image ion-padding-top"}
                src={ImageHelper.ImagePath(pulse.attachments.s3Location, pulse.eventType)}
                onClick={handleShow}
            />
            </div>

            <IonModal isOpen={showFull} onDidDismiss={handleClose} onClick={(e) => e.stopPropagation()}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle class="ion-text-center">{pulse.attachments.fileName}</IonTitle>
                        <IonButtons slot={"end"}>
                            <IonButton shape="round" fill="clear" mode="ios" color="light" onClick={handleClose}>
                                <IonIcon icon={close} style={{fontSize: '28px'}}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonImg class="ion-padding-top" src={ImageHelper.ImagePath(pulse.attachments.s3Location, pulse.eventType)} />
                </IonContent>
            </IonModal>
        </>
    );
}
