import React, {useState} from "react";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonRadio, IonRadioGroup, IonTitle, IonToolbar} from "@ionic/react";
import {close} from "ionicons/icons";

interface SubscriptionModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (type: string) => void;
    eventNumber: number;
}

const SubscriptionModal: React.FC<SubscriptionModalProps> = ({ isOpen, onClose, onSave,
                                                                 //eventNumber
}) => {
    const [selectedType, setSelectedType] = useState<string>("ALL");

    const handleSave = () => {
        onSave(selectedType);
    };

    return (
        <IonModal isOpen={isOpen} onDidDismiss={onClose}>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle >Subscription Type</IonTitle>
                    <IonButtons slot="end">
                        <IonButton shape="round" fill="clear" mode="ios" color="light"
                                   onClick={onClose}>
                            <IonIcon icon={close} style={{fontSize: "28px"}}/>
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

            </IonHeader>
                <IonContent>
                    <IonRadioGroup value={selectedType} onIonChange={e => setSelectedType(e.detail.value)}>
                        <IonItem>
                            <IonLabel>All notifications, anywhere</IonLabel>
                            <IonRadio slot="start" value="ALL" />
                        </IonItem>
                        <IonItem>
                            <IonLabel>Notifications while I&apos;m near the event</IonLabel>
                            <IonRadio slot="start" value="LOCAL" />
                        </IonItem>
                    </IonRadioGroup>
                    <IonButton expand="full" onClick={handleSave} color="tertiary">
                        Submit
                    </IonButton>
                </IonContent>
        </IonModal>
    );
};

export default SubscriptionModal;
