import {EventType} from "../viewModel/PulseView.model";

export const PUBLIC_BUCKET_NAME = import.meta.env.VITE_S3_PUBLIC_BUCKET as string;

export function ImagePath(imagePath: string, eventType: EventType) {
    if (eventType === EventType.PUBLIC) {
        return `${PUBLIC_BUCKET_NAME}${imagePath}`
    }
    throw new Error('PRIVATE EVENT IMAGES NOT SUPPORTED');
}
