import {EventViewModel} from "../viewModel/EventView.model";
import {EventServices} from "../services/EventServices";
import {createAsyncAction, errorResult, registerInDevtools, Store, successResult} from "pullstate";
import {EventUserListViewModel} from "../viewModel/EventUserListViewModel";
import {MyEventsPulseTrackerViewModel} from "../viewModel/MyEventsPulseTrackerView.model";
import {EventUserSubscriptionBean} from "../beanModels/EventUserSubscriptionBean.model";
import {PrivateEventUserSubscriptionBean} from '../beanModels/PrivateEventUserSubscriptionBean.model';


export interface EventStoreInterface {
    events: EventUserListViewModel[],
    userSubscribedEvents: EventUserListViewModel[]
    pulseTrackerResult: MyEventsPulseTrackerViewModel | null
}

export const EventStore = new Store<EventStoreInterface>({
    events: [],
    userSubscribedEvents: [],
    pulseTrackerResult: null
})


registerInDevtools({
    EventStore
});


export namespace EventStoreActions {


    export interface FindAllEventsOutput {
        events?: EventViewModel[]
    }

    export interface FindAllEventsIdInput {
        organizationId: number
    }

    export interface FindAllEventsByUserOutput {
        userSubscribedEvents?: EventUserListViewModel[]
    }

    export interface FindAllEventsByNameOutput {
        events?: EventUserListViewModel[];
    }

    export interface SelectEventByIdInput {
        eventId: number
    }


    /*    export interface FindAllEventsDistanceInput{
            distanceSearchParam:number
        }*/

    export interface FindAllEventsNameInput {
        nameSearchParam: string
    }

    export interface FindNewPulsesCountOutput {
        pulseTrackerResult?: MyEventsPulseTrackerViewModel
    }

    export interface FindNewPulsesCountInput {
        lastCheckedTime: string
    }

    export interface FindNewEventPulsesCountInput {
        lastCheckedTime: number,
        eventId: number
    }

    // export const findAllEventsByOrganizationId = createAsyncAction<FindAllEventsIdInput, FindAllEventsOutput>(
    //     async ({organizationId}) => {
    //         try {
    //             const result = await findEventListForOrganization(organizationId);
    //             EventStore.update(s=>{s.events=result})
    //             return successResult({ events: result });
    //         }
    //
    //         catch(error){
    //             return errorResult([], `Couldn't get events: ${error}`);
    //         }
    //     }
    // );

    export const findAllEventsByName = createAsyncAction<FindAllEventsNameInput, FindAllEventsByNameOutput>(
        async ({nameSearchParam}) => {
            try {
                const result = await EventServices.findEventListByName(nameSearchParam);
                return successResult({events: result as EventUserListViewModel[]});
            } catch (error) {
                return errorResult([], `Couldn't get events: ${error}`);
            }
        }
    );

    // export const findAllEvents = createAsyncAction<null, FindAllEventsOutput>(
    //     async () => {
    //         try {
    //             const result = await findAllEventsList();
    //             EventStore.update(s=>{s.events=result})
    //             return successResult({ events: result });
    //         }
    //
    //         catch(error){
    //             return errorResult([], `Couldn't get events: ${error}`);
    //         }
    //     }
    // );

    // Subscribe to an event
    export const subscribeToEventAction = createAsyncAction<EventUserSubscriptionBean.Model, null>(
        async (subscriptionBean) => {
            try {
                await EventServices.subscribeToEvent(subscriptionBean);
                return successResult(null);
            } catch (error) {
                return errorResult(undefined, `Couldn't subscribe to event: ${error}`);
            }
        }, {
            postActionHook: ({ args, result }) => {
                if (!result.error) {
                    EventStore.update(s => {
                        const index = s.events.findIndex(e => e.eventId === args.eventId);
                        s.events[index].subscribed = true;
                        s.events[index].subscriptionType = args.subscriptionType;
                        s.userSubscribedEvents.push(s.events[index]);
                    });
                }
            }
        }
    );

    export const subscribeToPrivateEventAction = createAsyncAction<PrivateEventUserSubscriptionBean.Model, null>(
        async (subscriptionBean) => {
            try {
                const postResult = await EventServices.subscribeToPrivateEvent(subscriptionBean);
                console.log('Post Result:', postResult);
                return successResult(null);
            } catch (error) {
                console.log('Post Error:', error);
                return errorResult(undefined, `Couldn't subscribe to event: ${error}`, error);
            }
        }
    );

    // Unsubscribe from an event
    export const unsubscribeFromEventAction = createAsyncAction<number, null>(
        async (eventId) => {
            try {
                await EventServices.unsubscribeFromEvent(eventId);
                return successResult(null);
            } catch (error) {
                return errorResult(undefined, `Couldn't unsubscribe from event: ${error}`);
            }
        }, {
            postActionHook: ({ args, result }) => {
                if (!result.error) {
                    EventStore.update(s => {
                        const index = s.userSubscribedEvents.findIndex(e => e.eventId === args);
                        s.userSubscribedEvents.splice(index, 1);
                        const indexEvent = s.events.findIndex(e => e.eventId === args);
                        if (indexEvent != null) {
                            s.events[indexEvent].subscribed = false;
                            s.events[indexEvent].subscriptionType = "";
                        }
                    });
                }
            }
        }
    );

    export const updateSubscriptionAction = createAsyncAction<EventUserSubscriptionBean.Model, null>(
        async (subscriptionBean) => {
            try {
                await EventServices.editEventSubscription(subscriptionBean);
                return successResult(null);
            } catch (error) {
                return errorResult(undefined, `Couldn't update subscription: ${error}`);
            }
        }, {
            postActionHook: ({ args, result }) => {
                if (!result.error) {
                    EventStore.update(s => {
                        const index = s.events.findIndex(e => e.eventId === args.eventId);
                        s.events[index].subscriptionType = args.subscriptionType;
                    });
                }
            }
        }
    );

    export const findAllEventsByUser = createAsyncAction<null, FindAllEventsByUserOutput>(
        async () => {
            try {
                const result = await EventServices.findAllEventsByUser();

                return successResult({userSubscribedEvents: result});
            } catch (error: any) {
                return errorResult([], error.message);
            }
        }, {
            cacheBreakHook: ()=> true,
            postActionHook: ({args: _args, result}) =>{
                if (!result.error ) {
                    EventStore.update(s => {
                        s.events = result.payload.userSubscribedEvents ? result.payload.userSubscribedEvents: []
                        s.userSubscribedEvents = result.payload.userSubscribedEvents ? result.payload.userSubscribedEvents: []
                    })
                }
            }
        },
    );

    export const findNewPulsesCountAction = createAsyncAction<FindNewPulsesCountInput, FindNewPulsesCountOutput>(
        async ({ lastCheckedTime }) => {
            try {
                const result = await EventServices.findNewPulsesCount(lastCheckedTime);
                EventStore.update(s => {
                    s.pulseTrackerResult = result;
                })
                return successResult({ pulseTrackerResult: result });
            } catch (error) {
                return errorResult(undefined, `Couldn't get new pulses count: ${error}`);
            }
        }
    );

    export const findNewEventPulsesCountAction = createAsyncAction(
        async ({ lastCheckedTime, eventId }) => {
            try {
                const result = await EventServices.findNewEventPulsesCount(lastCheckedTime, eventId);
                return successResult({ newEventPulseCount: result });
            } catch (error) {
                return errorResult(undefined, `Couldn't get new pulses count: ${error}`);
            }
        }
    );
}
