import React, {useState} from 'react';
import {IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonTextarea, IonTitle, IonToolbar} from '@ionic/react';
import {close} from 'ionicons/icons';
import {PulseServices} from "../../services/PulseServices";
import {ReportedContentAddBean} from "../../beanModels/ReportedContentAddBean.model";
import {PulseStoreActions} from "../../store/PulseStore";

export interface ReportPulseButtonInputs{
    pulseId?:number,
    eventId?:number,
    completed?:()=>void
}

const ReportPulseButton = ({pulseId, eventId,completed}:ReportPulseButtonInputs) => {
    const [showModal, setShowModal] = useState(false);
    const [reason, setReason] = useState('');

    const handleSave = async () => {
        if (!reason) {
            alert("Please provide a reason for reporting.");
            return;
        }
        if(pulseId && eventId){
            const bean :ReportedContentAddBean.Model = {pulseId: pulseId, userReportMessage:reason}

            PulseServices.reportPulseForContentViolation(bean).then(() =>{
                setShowModal(false);
                PulseStoreActions.findAllPulsesByEventId.run({eventId: eventId}).then(()=>{})
                if (completed) {
                    completed();
                }
            }).catch(error =>{
                console.log(error);
            })
        }

    };

    return (
        <>
            <IonButton fill={"clear"} onClick={() => setShowModal(true)}>
                Report Pulse
                {/*<IonIcon icon={alertCircleOutline} slot="icon-only"/>*/}
            </IonButton>

            <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
                <IonHeader>
                    <IonToolbar color="primary" >
                        <IonTitle class="ion-text-center">Report Pulse</IonTitle>
                        <IonButtons slot={"end"}>
                            <IonButton shape="round" fill="clear" mode="ios" color="light"  onClick={() => setShowModal(false)}>
                                <IonIcon icon={close} style={{fontSize: '28px'}}/>
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent className="ion-padding">
                    <IonItem>
                        <IonLabel position="stacked">Reason</IonLabel>
                        <IonTextarea
                            value={reason}
                            onIonChange={(e) => setReason(e.detail.value as string)}
                            placeholder="Enter reason for reporting"
                        />
                    </IonItem>
                </IonContent>

                <IonFooter>
                    <IonButton expand="block" onClick={handleSave} style={{margin: '16px'}}>
                        Save
                    </IonButton>
                </IonFooter>
            </IonModal>
        </>
    );


};

export default ReportPulseButton;
