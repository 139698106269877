import React, {useState} from "react";
import {IonAlert, IonButton, IonIcon} from "@ionic/react";
import {EventStoreActions} from "../../store/EventStore";
import SubscriptionModal from "./SubscriptionModal";

import subscribe from '../../assets/subscribe.svg';
import unsubscribe from '../../assets/unsubscribe.svg';
import {createOutline} from "ionicons/icons";

interface SubscriptionToggleButtonProps {
    eventId: number;
    subscribed: boolean;
    subscriptionType?: string;
    eventNumber: number;
}

const SubscriptionToggleButton: React.FC<SubscriptionToggleButtonProps> = ({ eventId, subscribed
                                                                               //, subscriptionType
                                                                               ,eventNumber }) => {
    const [showModal, setShowModal] = useState(false);
    const [showUnsubAlert, setShowUnsubAlert] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleSubscribe = (type: string) => {
        const subscriptionBean = {
            eventId: eventId,
            subscriptionType: type,
        };

        EventStoreActions.subscribeToEventAction.run(subscriptionBean).then(() => {
            console.log(`Subscribed to event ${eventId} with ${type}`);
            closeModal();
        }).catch(error => {
            console.error("Error subscribing to event:", error);
        });
    };

    const handleUnsubscribe = () => {
        EventStoreActions.unsubscribeFromEventAction.run(eventId).then(() => {
            console.log(`Unsubscribed from event ${eventId}`);
        }).catch(error => {
            console.error("Error unsubscribing from event:", error);
        });
    };

    const handleEditSubscription = (type: string) => {
        const subscriptionBean = {
            eventId: eventId,
            subscriptionType: type,
        };

        EventStoreActions.updateSubscriptionAction.run(subscriptionBean).then(() => {
            console.log(`Updated subscription for event ${eventId} to ${type}`);
            closeModal();
        }).catch(error => {
            console.error("Error updating subscription:", error);
        });
    };

    return (
        <>
                {!subscribed ? (
                    <IonButton
                        fill="solid"
                        onClick={openModal} color="tertiary" style={{
                        // paddingTop: '0px',
                        // fontSize: '0.9em',
                        // marginTop: "-12px"
                        paddingLeft: '5px',
                        paddingBottom: '5px'
                        // float:'right'
                    }}>
                        <IonIcon src={subscribe}/>
                        Subscribe
                    </IonButton>
                ) : (
                    <>
                        <IonButton fill="solid" onClick={openModal} color="primary" style={{
                            // flexBasis: '48%',
                            // fontSize: '0.9em'
                            // float:'right'
                            paddingLeft: '5px',
                            paddingBottom: '5px'
                        }}>
                            <IonIcon icon={createOutline}></IonIcon>
                            Subscription
                        </IonButton>
                        <IonButton fill="solid" onClick={() => setShowUnsubAlert(true)} color="danger" style={{
                            /*flexBasis: '48%',
                            fontSize: '0.9em'*/
                            // float:'right'
                            paddingLeft: '5px',
                            paddingBottom: '5px'
                        }}>
                            <IonIcon src={unsubscribe}/>
                            Unsubscribe
                        </IonButton>

                    </>
                )}
                {/*<p style={{ marginTop: "2px", fontSize: "0.77em", marginLeft: "4px", marginBottom: "-9px"}}>*/}
                {/*    {subscribed*/}
                {/*        ? subscriptionType === "ALL"*/}
                {/*            ? "You're subscribed to all pulse notifications for this event"*/}
                {/*            : "You're subscribed to localized pulse notifications for this event"*/}
                {/*        : "You aren't subscribed to this event"}*/}
                {/*</p>*/}
                <SubscriptionModal
                    isOpen={showModal}
                    onClose={closeModal}
                    onSave={subscribed ? handleEditSubscription : handleSubscribe}
                    eventNumber={eventNumber}
                />
                <IonAlert
                    isOpen={showUnsubAlert}
                    onDidDismiss={() => setShowUnsubAlert(false)}
                    header="Confirm Unsubscribe"
                    message="Are you sure you want to unsubscribe from this event?"
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            handler: () => setShowUnsubAlert(false)
                        },
                        {
                            text: 'Unsubscribe',
                            handler: () => {
                                handleUnsubscribe();
                                setShowUnsubAlert(false);
                            }
                        }
                    ]}
                />
        </>
    );
};

export default SubscriptionToggleButton;
