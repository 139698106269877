import {IonAlert, IonIcon, IonItem, IonLabel} from "@ionic/react";
import {trashBinOutline} from "ionicons/icons";
import React from "react";
import {UserService} from "../../services/UserService";
import {UserStore} from "../../store/UserStore";
import {useHistory} from "react-router-dom";

export function DeleteAccount() {
    const history = useHistory();

    const deleteAccountHandler = async () => {
        console.log("Delete Account");
        try {
            await UserService.deleteAccount()
            const signOutHook = UserStore.getRawState().signOutHook;
            if (signOutHook){
                signOutHook();
            }
            history.push('/myevents');
        } catch(err) {
            // handle error
            console.error(err);
        }
    };

    return (
        <>
            <IonItem button={true} id="delete-account">
                <IonIcon color="danger" slot="start" icon={trashBinOutline}></IonIcon>
                <IonLabel color="danger">Delete Account</IonLabel>
            </IonItem>
            <IonAlert
                trigger="delete-account"
                header="Delete Event Pulse Account"
                message="This will immediately delete your Event Pulse Account.  Are you sure?"
                buttons={[
                    {
                        text: 'Delete Account',
                        role: 'confirm',
                        handler: deleteAccountHandler
                    }]}
            ></IonAlert>
        </>
    )
}
