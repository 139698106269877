import {del, get, post} from "aws-amplify/api";
import {Responses} from "../type/StandardResponse";
import {EventStore} from "../store/EventStore";
import {EventUserListViewModel} from "../viewModel/EventUserListViewModel";
import {MyEventsPulseTrackerViewModel} from "../viewModel/MyEventsPulseTrackerView.model";
import {EventUserSubscriptionBean} from "../beanModels/EventUserSubscriptionBean.model";
import {PrivateEventUserSubscriptionBean} from '../beanModels/PrivateEventUserSubscriptionBean.model';

export namespace EventServices {
    // export async function findEventListForOrganization(organizationId: number): Promise<EventViewModel[]> {
    //     try {
    //         const restOperation = get({
    //             apiName: 'API',
    //             path: `/event/byOrg/${organizationId}`
    //         });
    //         const {body} = await restOperation.response;
    //         const result = Responses.fromDocumentType<EventViewModel[]>(await body.json());
    //         if (result.error) {
    //             throw new Error(result.error.errorMessage);
    //         }
    //         EventStore.update(e => {
    //             e.events = result.body;
    //         })
    //         return result.body;
    //     } catch (error) {
    //         throw error;
    //     }
    // }


    export async function findEventListByName(nameSearchParam: string): Promise<EventUserListViewModel[]> {
        const restOperation = post({
            apiName: 'API',
            path: `/event/search`, options: {body: {searchParam: nameSearchParam}}
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<EventUserListViewModel[]>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        EventStore.update(e => {
            e.events = result.body;
        })
        return result.body;
    }

    // export async function findEventListByDistance(distanceSearchParam: number): Promise<EventViewModel[]> {
    //     try {
    //         const restOperation = post({
    //             apiName: 'API',
    //             path: `/event/byDistance/${distanceSearchParam}`
    //         });
    //         const {body} = await restOperation.response;
    //         const result = Responses.fromDocumentType<EventViewModel[]>(await body.json());
    //         if (result.error) {
    //             throw new Error(result.error.errorMessage);
    //         }
    //         EventStore.update(e => {
    //             e.events = result.body;
    //         })
    //         return result.body;
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    // export async function findAllEventsList(): Promise<EventViewModel[]> {
    //     try {
    //         const restOperation = get({
    //             apiName: 'API',
    //             path: `/event/findAll`
    //         });
    //         const {body} = await restOperation.response;
    //         const result = Responses.fromDocumentType<EventViewModel[]>(await body.json());
    //         if (result.error) {
    //             throw new Error(result.error.errorMessage);
    //         }
    //         EventStore.update(e => {
    //             e.events = result.body;
    //         })
    //         return result.body;
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    /*    export async function subscribeToEvent(eventId: number): Promise<void> {
            try {
                await post({
                    apiName: 'API',
                    path: `/event/subscribe`,
                    options: { body: { eventId } }
                });
            } catch (error) {
                throw error;
            }
        }*/

    export async function subscribeToEvent(subscriptionBean: EventUserSubscriptionBean.Model): Promise<void> {
        await post({
            apiName: 'API',
            path: `/event/subscribe`,
            options: {body: EventUserSubscriptionBean.toDocumentType(subscriptionBean)}
        });
    }

    export async function subscribeToPrivateEvent(subscriptionBean: PrivateEventUserSubscriptionBean.Model): Promise<number> {
        const postOp = post({
            apiName: 'API',
            path: '/event/join',
            options: {body: PrivateEventUserSubscriptionBean.toDocumentType(subscriptionBean)}
        });
        const {body} = await postOp.response;
        const result = Responses.fromDocumentType<number>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage, {cause: result.error.errorCode});
        }
        return result.body;
    }

    export async function unsubscribeFromEvent(eventId: number): Promise<void> {
        await del({
            apiName: 'API',
            path: `/event/unsubscribe/${eventId}`
        });
    }

    export async function editEventSubscription(subscriptionBean: EventUserSubscriptionBean.Model): Promise<void> {
        await post({
            apiName: 'API',
            path: `/event/edit-subscription`,
            options: {body: EventUserSubscriptionBean.toDocumentType(subscriptionBean)}
        });
    }

    export async function findAllEventsByUser(): Promise<EventUserListViewModel[]> {
        const restOperation = get({
            apiName: 'API',
            path: `/event/user-events`, options: {}
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<EventUserListViewModel[]>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        EventStore.update(e => {
            e.userSubscribedEvents = result.body;
        })
        return result.body;
    }

    export async function findNewPulsesCount(lastCheckedTime: string): Promise<MyEventsPulseTrackerViewModel> {
        const restOperation = post({
            apiName: 'API',
            path: `/event/new-pulses`,
            options: {body: {lastCheckedTime}}
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<MyEventsPulseTrackerViewModel>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        return result.body;
    }

    export async function findNewEventPulsesCount(lastCheckedTime: number, eventId: number): Promise<number> {
        const restOperation = post({
            apiName: 'API',
            path: `/event/event-pulse-refresh`,
            options: {body: {lastCheckedTime, eventId}}
        });
        const {body} = await restOperation.response;
        const result = Responses.fromDocumentType<number>(await body.json());
        if (result.error) {
            throw new Error(result.error.errorMessage);
        }
        return result.body;
    }
}
