import React, {Dispatch, ForwardedRef, SetStateAction, useEffect, useRef, useState} from "react";

import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonFab,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonSpinner,
    IonTitle,
    IonToolbar,
    RefresherEventDetail
} from "@ionic/react";
import {close, refreshCircle, swapVertical} from 'ionicons/icons';
import {PulseStoreActions} from "../../store/PulseStore";
import {EventStoreActions} from "../../store/EventStore";
import {PulseViewModel} from "../../viewModel/PulseView.model";
import './PulseListContainer.scss';
import ReportPulseButton from "../ReportPulse/ReportPulseModal";
import {PulseContent} from "./PulseContent";
import {Components, ScrollerProps, Virtuoso} from "react-virtuoso";
import {PulseList} from "./PulseList";

interface PulseListContainerProps {
    eventId: number;
    pulseId?: number;
}

const PulseListContainer: React.FC<PulseListContainerProps> = (props) => {
    const {eventId, pulseId} = props;

    const [_start, _finished, result, loading] = PulseStoreActions.findAllPulsesByEventId.useWatch({eventId});
    const pulses = result.payload?.pulses;

    const [newPulseCount, setNewPulseCount] = useState<number>(0);
    const [isReversed, setIsReversed] = useState(true);
    const lastCheckedTimeRef = useRef<Date | null>(null);
    const [showPulseDetail, setShowPulseDetail] = useState<boolean>(false);
    const [pulseDetail, setPulseDetail] = useState<PulseViewModel | undefined>(undefined);

    const [showRefresher, setShowRefresher] = useState<boolean>(false);

    // Initial data fetching and setup
    useEffect(() => {
        fetchPulses();

        const interval = setInterval(checkForNewPulses, 15000); // periodic check for new pulses

        return () => {
            clearInterval(interval);
        };
    }, []);

    // Fetch initial pulse list
    const fetchPulses = async () => {
        //use isLoading
        // setLoading(true);
        try {
            await PulseStoreActions.findAllPulsesByEventId.run({eventId});
            lastCheckedTimeRef.current = new Date();
        } catch (error) {
            console.error("Error fetching pulses:", error);
        } finally {
            // setLoading(false);
        }
    };

    // Check for new pulses since the last checked time
    const checkForNewPulses = async () => {
        if (!lastCheckedTimeRef.current) return;
        try {
            const {payload} = await EventStoreActions.findNewEventPulsesCountAction.run({
                lastCheckedTime: lastCheckedTimeRef.current.toISOString(),
                eventId,
            });
            setNewPulseCount(payload?.newEventPulseCount ?? 0);
        } catch (error) {
            console.error("Error checking for new pulses:", error);
        }
    };

    // Manually refresh pulse list
    const refreshPulses = async (cb?: () => void) => {
        await fetchPulses(); // Reload pulses
        setNewPulseCount(0); // Reset new pulse count
        cb && cb();
    };

    const toggleOrder = () => {
        setIsReversed(!isReversed);
    };

    useEffect(() => {
        if (pulseId != null) {
            const targetElement = document.getElementById(pulseId.toString(10))
            if (targetElement != null) {
                targetElement.scrollIntoView({behavior: 'smooth', block: 'center'});
                targetElement.classList.add('pulseItem', 'pulseItemFlash');
                setPulseDetail(pulses?.find(p => p.pulseId === pulseId));
                setTimeout(() => targetElement.classList.remove('pulseItemFlash'), 5000);
                setTimeout(() => setShowPulseDetail(true), 0);
                setTimeout(() => targetElement.classList.remove('pulseItem'), 15000);
            }
        }
    }, [pulseId, pulses]);

    // Function to render the list of pulses
    return (
        <>
            <IonGrid className={"secondary-bg"} style={{position: 'relative'}}>
                <IonRow>
                    <IonCol size={"8"} className={"ion-align-self-center"}>
                        <IonLabel style={{fontSize: '1.5em'}}>Pulses {pulses && '(' + pulses?.length + ')'}</IonLabel>
                    </IonCol>
                    <IonCol size={"4"} className={"ion-align-self-center ion-text-end"}>
                        <IonButton size={"small"} fill="clear" onClick={toggleOrder}>
                            <IonLabel>{isReversed ? "Newest" : "Oldest"}</IonLabel>
                            <IonIcon icon={swapVertical}/>
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>

            {!loading && pulses && pulses?.length > 0 && (
                <>
                    <PulseList refreshPulses={refreshPulses} pulses={pulses} itemClick={(x: PulseViewModel) => {
                        setPulseDetail(x);
                        setShowPulseDetail(true);
                    }} isReversed={isReversed} newPulseCount={newPulseCount} />
                </>
            )}
            {loading && pulses && pulses.length === 0 && (
                <IonItem>No Pulses for this Event!</IonItem>
            )}

            <IonModal isOpen={showPulseDetail} onDidDismiss={() => setShowPulseDetail(false)}>
                <IonHeader>
                    <IonToolbar color="primary">
                        <IonTitle>Pulse Detail</IonTitle>
                        <IonButton shape="round" fill="clear" mode="ios" color="light" slot="end" onClick={() => setShowPulseDetail(false)}>
                            <IonIcon icon={close} style={{fontSize: '28px'}}/>
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList inset={true}>
                        <IonItem>
                            {pulseDetail ? <PulseContent pulse={pulseDetail} showReadMore={false} showHorizontalRule={true}></PulseContent> : <></>}
                        </IonItem>
                    </IonList>
                    <IonToolbar>
                        <IonButtons slot={"end"}>
                            <ReportPulseButton pulseId={pulseDetail?.pulseId} eventId={pulseDetail?.eventId}
                                               completed={() => setShowPulseDetail(false)}/>
                        </IonButtons>
                    </IonToolbar>
                </IonContent>
            </IonModal>
        </>
    );
};



export default PulseListContainer;
