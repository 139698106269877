import {DocumentType} from "@aws-amplify/core/dist/esm/libraryUtils";


export namespace ReportedContentAddBean{
    export interface Model{
        userId?: number,
        pulseId :number,
        userReportMessage: string
    }

    export function toDocumentType(bean:Model):DocumentType{
        return bean as unknown as DocumentType

    }
}
