import {IonLabel, IonText} from "@ionic/react";
import React from "react";
import {PulseViewModel} from "../../viewModel/PulseView.model";
import {format, parseISO} from "date-fns";
import {DATEFORMATSTRING, TIMEFORMATSTRING} from "../../constants";
import {splitText} from "../../utilities/PulseMessageHelper";
import {PulseImageView} from "./PulseImageView";

export interface PulseContentProps {
    pulse: PulseViewModel;
    showReadMore: boolean;
    showHorizontalRule: boolean;
}

export function PulseContent({pulse, showReadMore, showHorizontalRule = false}: PulseContentProps) {
    const dateObj = parseISO(pulse.postDate);
    const formattedDate = format(dateObj, DATEFORMATSTRING);
    const formattedTime = format(dateObj, TIMEFORMATSTRING);
    let pulseText;
    if (pulse && pulse.attachments) {
        pulseText = <PulseImageView pulse={pulse}/>
    } else {
        pulseText = <IonText>
            <pre className={'preWrap'}>{pulse.message}</pre>
        </IonText>;
        if (showReadMore) {
            const split = splitText(pulse.message);
            pulseText = <IonText>{split.text}{split.wasSplit && <span className={'muted'}>&nbsp;Read More</span>}</IonText>
        }
    }


    return (
        <IonLabel>
            <IonLabel><strong>{pulse.pulseName}</strong></IonLabel>
            <p><small>{formattedDate}, {formattedTime}</small></p>
            {pulse.announcerFirstName && <p><small>From: {pulse.announcerFirstName} {pulse.announcerLastName}</small></p>}
            {pulse.pulseCategory && <p>Category: {pulse.pulseCategory}</p>}
            {showHorizontalRule && <hr style={{backgroundColor: "black"}}/>}
            {pulseText}
        </IonLabel>
    )
}
