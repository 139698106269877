import React from "react";


export function LegalDocumentsLabel() {
    return (
        <>I agree with the <a
            href="https://www.eventpulse.app/legal-documents"
            target="_blank"
            rel="noopener noreferrer"
            style={{textDecoration: 'underline', color: 'blue'}}
        >
            Terms & Conditions
        </a>
        </>
    )
}
