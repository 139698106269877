type Listener = (...args: unknown[]) => void;

interface Events {
    [event: string]: Listener[];
}

class EventEmitter {
    private events: Events = {};

    on(event: string, listener: Listener): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(listener);
    }

    emit(event: string, ...args: unknown[]): void {
        if (this.events[event]) {
            this.events[event].forEach(listener => listener(...args));
        }
    }


    off(event: string | number, listener: unknown) {
        if (!this.events[event]) return;

        this.events[event] = this.events[event].filter(l => l !== listener);
    }
}

// Create an instance of the EventEmitter
const eventEmitter = new EventEmitter();

export default eventEmitter;
