import { createAsyncAction, errorResult, registerInDevtools, Store, successResult } from "pullstate";
import { UserViewModel } from "../viewModel/UserView.model";
import {EventViewModel} from '../viewModel/EventView.model';
import {UserService} from '../services/UserService';
import {SignOutType} from "../type/SignOutType";


export interface UserStoreInterface {
    user: UserViewModel | null;
    signOutHook: SignOutType
}

export const UserStore = new Store<UserStoreInterface>({
    user: null,
    signOutHook: () => {
        console.error("No Sign Out Hook Registered");
    }
});

registerInDevtools({
    UserStore,
});

export namespace UserStoreActions {
    export interface WhoAmIEvents {
        events?: EventViewModel[];
    }

    export interface WhoAmIOutput {
        user: UserViewModel;
    }
    export const whoAmI = createAsyncAction<WhoAmIEvents, WhoAmIOutput>(
        async (_) => {
            try {
                const result = await UserService.whoAmI();
                UserStore.update((s) => {
                    s.user = result;
                });
                return successResult({user: result});
            } catch (error) {
                console.log('error fired', error);
                return errorResult([], `Who am I? ${error}`);
            }
        }
    )

    /*export const getUserByCognitoId = createAsyncAction<GetUserByIdInput, GetUserByIdOutput>(
        async ({ cognitoUserId }) => {
            try {
                const user = await UserServices.getUserByCognitoId(cognitoUserId);
                return successResult({ user });
            } catch (error) {
                return errorResult(undefined, `Couldn't get user: ${error}`);
            }
        }
    );*/

}
