import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonImg,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './MyEvents.css';
import UserEventList from "../components/Events/UserEventList";
import React from "react";
import PrivateEventCommonButton from '../components/PrivateEvents/PrivateEventCommonButton';

const MyEvents: React.FC = () => {



  return (
    <IonPage>
        <IonHeader>
            <IonToolbar color="primary">
                <IonButtons slot="start">
                    <IonButton>
                        <IonImg src="/EP-logo-small.png"></IonImg>
                    </IonButton>
                </IonButtons>
                <IonTitle>
                    My Events
                </IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent color="secondary">
                <IonToolbar color="secondary" style={{paddingTop:"0.5em"}}>
                        <IonText slot="start" style={{paddingLeft:"0.7em"}}><h4 style={{fontWeight:"bold"}}>Subscribed Events</h4></IonText>
                        <div slot="end" style={{paddingRight:"0.5em"}}>
                            <PrivateEventCommonButton/>
                        </div>
                </IonToolbar>
                <div style={{paddingTop:"0.5em"}}>
                    <UserEventList/>
                </div>
        </IonContent>

    </IonPage>
  );
};

export default MyEvents;
