import {PulseViewModel} from "../../viewModel/PulseView.model";
import React, {Dispatch, ForwardedRef, SetStateAction, useState} from "react";
import {IonButton, IonFab, IonIcon, IonItem, IonSpinner, RefresherEventDetail} from "@ionic/react";
import {refreshCircle} from "ionicons/icons";
import {ScrollerProps, Virtuoso} from "react-virtuoso";
import {PulseContent} from "./PulseContent";

export interface PulseListProps {
    pulses: PulseViewModel[],
    itemClick: (x:PulseViewModel) => void,
    refreshPulses: (cb?: () => void) => void,
    isReversed: boolean,
    newPulseCount: number
}

export const PulseList: React.FC<PulseListProps> = ({pulses, itemClick, isReversed, refreshPulses, newPulseCount}) => {
    const [showRefresher, setShowRefresher] = useState<boolean>(false);

    const displayPulses = isReversed ? pulses.slice().reverse() : pulses;


    return (
        <div className={"event-pulse-pulse-list-container"}>
            {newPulseCount !== 0 &&
                <IonFab slot={"fixed"} horizontal={"center"}>
                    <IonButton size={"small"} color="warning" onClick={() => refreshPulses()}><IonIcon slot={"start"} icon={refreshCircle}></IonIcon>{newPulseCount} New Pulses</IonButton>
                </IonFab>
            }


            {/*<IonList lines="full">*/}
            {showRefresher && <div className={"ion-text-center ion-padding"}><IonSpinner/><hr className={"secondary-bg"}/></div>}
            <Virtuoso
                // style={{height: "73%"}}
                data={displayPulses}
                itemContent={(index, pulse) => {
                    return (<div style={{minHeight: "20px"}}>
                        <IonItem key={pulse.pulseId}
                                 id={pulse.pulseId.toString(10)}
                                 className={'pulseListItem'}
                                 onClick={() => itemClick(pulse)}>
                            <PulseContent pulse={pulse} showReadMore={true} showHorizontalRule={false}></PulseContent>
                        </IonItem>
                    </div>)
                }}
                context={{refresh: (cb) => {
                        refreshPulses(cb)
                    }, startPullDown: () =>{
                        setShowRefresher(true)
                    }, endPullDown: () => {
                        setShowRefresher(false);
                    }}}
                components={{Scroller: CustomScroller}}
            />
        </div>
    );
};


interface CustomScrollerContextProps {
    refresh: (cb: () => void) => void,
    startPullDown: () => void,
    endPullDown: () => void
}

const coords = {
    startX: 0,
    startY: 0,
    moveX: 0,
    moveY: 0,
    endX: 0,
    endY: 0,
    pulledDown: false
};

const CustomScroller = React.forwardRef(({style, ...props}: ScrollerProps & { context?: CustomScrollerContextProps }, ref: ForwardedRef<HTMLDivElement>) => {
    // an alternative option to assign the ref is
    // <div ref={(r) => ref.current = r}>
    function isPulledDown(dX: number, dY: number) {
        return  dY < 0 &&
            ((Math.abs(dX) <= 100 && Math.abs(dY) >= 300) ||
                (Math.abs(dX) / Math.abs(dY) <= 0.3 && dY >= 60))
    }

    function resetCoords() {
        coords.startX = coords.startY = coords.moveY = coords.moveX = coords.endX = coords.endY = 0;
    }

    function setPulledDown(val: boolean) {
        coords.pulledDown = val;
    }


    return <div id={"scroller-refresher"} style={{...style}} ref={ref} {...props}
                onTouchStart={(evt) => {
                    resetCoords();
                    if (evt.targetTouches) {
                        const touch = evt.targetTouches[0];
                        coords.startX = touch.screenX;
                        coords.startY = touch.screenY;
                        setPulledDown(false);
                    }
                }}
                onTouchMove={(evt) =>{
                    if (evt.changedTouches) {
                        const touch = evt.changedTouches[0];
                        coords.moveX = touch.screenX;
                        coords.moveY = touch.screenY;
                        const dX = coords.startX - coords.moveX;
                        const dY = coords.startY - coords.moveY;
                        const pulledDown = isPulledDown(dX, dY);
                        if (pulledDown ) {
                            setPulledDown(true);
                            props.context?.startPullDown();
                        }
                    }
                }}
                onTouchEnd={(evt) => {
                    if (evt.changedTouches) {
                        if (coords.pulledDown) {
                            setPulledDown(false);
                            const scroller = document.getElementById('scroller-refresher');

                            if (scroller && scroller.scrollTop === 0) {
                                console.log(props, props.context, props.context?.refresh);
                               props.context?.refresh( props.context?.endPullDown);
                            }
                        } else {
                            props.context?.endPullDown();
                        }

                    }
                }}/>

})
