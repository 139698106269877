import { DocumentType } from '@aws-amplify/core/internals/utils';

export namespace EventUserSubscriptionBean{

    export interface Model{
        eventId:number;
        subscriptionType:string;
    }


    export function toDocumentType(bean:Model):DocumentType{
         return bean as unknown as DocumentType
    }
}
