import {Authenticator, CheckboxField, useAuthenticator} from "@aws-amplify/ui-react";
import {LegalDocumentsLabel} from "../Settings/LegalDocumentsLabel";
import React from "react";


export function SignUpFormFields() {
    const {validationErrors} = useAuthenticator();

    return (
        <>
            <Authenticator.SignUp.FormFields/>

            <CheckboxField
                errorMessage={(String(validationErrors.acknowledgement))}
                hasError={!!validationErrors.acknowledgement}
                name="acknowledgement"
                value="yes"
                label={<LegalDocumentsLabel/>}
                color={"black"}
            />
        </>
    );
}
