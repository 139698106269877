import React, { useState } from 'react';
import {
    IonButton, IonButtons,
    IonCardContent,
    IonContent,
    IonHeader,
    IonIcon, IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage, IonText,
    IonTitle,
    IonToolbar,
    isPlatform,
    useIonViewDidLeave,
} from '@ionic/react';
import { EventStoreActions } from '../store/EventStore';
import { EventUserListViewModel } from "../viewModel/EventUserListViewModel";
import {chevronForward} from "ionicons/icons";
import PrivateEventCommonButton from '../components/PrivateEvents/PrivateEventCommonButton';

const Discover: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState<EventUserListViewModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [searchPerformed, setSearchPerformed] = useState(false);

    useIonViewDidLeave(() => {
        // Clear search results, reset search term and other states when leaving view
        setSearchResults([]);
        setSearchTerm('');
        setError('');
        setSearchPerformed(false);
    });

    const handleSearch = async () => {
        setLoading(true);
        setError('');
        setSearchPerformed(true);

        try {
            const res = await EventStoreActions.findAllEventsByName.run({ nameSearchParam: searchTerm });
            if (res.payload?.events) {
                setSearchResults(res.payload.events);
            }
        } catch (error) {
            console.error(error);
            setError('Error occurred while searching');
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event: CustomEvent) => {
        setSearchTerm(event.detail.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLIonInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton>
                            <IonImg src="/EP-logo-small.png"></IonImg>
                        </IonButton>
                    </IonButtons>
                    <IonTitle class="ion-text-center">
                        Discover
                    </IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent color="secondary">
                <IonToolbar color="secondary" style={{paddingTop:"0.5em"}}>
                    <IonText slot="start" style={{paddingLeft:"0.7em"}}><h4 style={{fontWeight:"bold"}}>Discover Events</h4></IonText>
                    <div slot="end" style={{paddingRight:"0.5em"}}>
                        <PrivateEventCommonButton/>
                    </div>
                </IonToolbar>
                <div>
                    <p style={{paddingLeft: '1em'}}>Search for events by name or event number:</p>

                    <IonItem style={{paddingTop: '1em'}}>
                        <IonInput
                            placeholder="Search by name or number"
                            value={searchTerm}
                            onIonInput={handleInputChange}
                            onKeyDown={handleKeyDown}
                            autocapitalize="sentences"
                            autocorrect="on"
                        ></IonInput>
                        <IonButton
                            onClick={handleSearch}
                            disabled={loading}
                            color="tertiary"
                        >Search</IonButton>
                    </IonItem>

                    {loading && <p style={{paddingLeft: '1em', paddingTop: '0.9em'}}>Loading...</p>}
                    {error && <p style={{paddingLeft: '1em', paddingTop: '0.9em'}}>{error}</p>}

                    {!loading && searchResults.length > 0 && (
                        <IonList>
                            {searchResults.map((event) => (
                                <IonItem key={event.eventId} routerLink={`/discover/event/${event.eventId}`}
                                         style={{paddingLeft: "0.5em", paddingRight:"0.4em"}}>
                                    <IonLabel>
                                        <h2>{event.eventName}</h2>
                                        <IonLabel>
                                            <p style={{paddingTop: '0.1em', color: 'grey'}}>Event
                                                No.{event.eventNumber} &nbsp;- &nbsp;{event.pulseCount} pulse{event.pulseCount !== 1 ? 's' : ''}</p>
                                        </IonLabel>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {event.subscribed && (
                                                <IonButton color="tertiary" style={{
                                                    marginRight: '4px',
                                                    marginLeft: '0px',
                                                    marginTop: '0px',
                                                    marginBottom: '0px',
                                                    fontSize: '0.75em',
                                                }}>
                                                    Subscribed!
                                                </IonButton>
                                            )}
                                            <h3 style={{color: 'grey', marginTop: '0px'}}>{event.organizationName}</h3>
                                        </div>
                                    </IonLabel>
                                    {!isPlatform("ios") && (
                                        <span style={{
                                            marginLeft: "auto",
                                            paddingRight: "2px",
                                            color: "tertiary",
                                            fontSize: "21px"
                                        }}>
                                            <IonIcon icon={chevronForward}/>
                                        </span>
                                    )}
                                </IonItem>
                            ))}
                        </IonList>
                    )}

                    {/* Only render the "No events found!" message when a search has been performed and there are no results */}
                    {!loading && searchResults.length === 0 && searchPerformed && !error && (
                        <p  style={{paddingLeft: '1em', paddingTop: '0.9em'}}>No events found!</p>
                    )}

                </div>
            </IonContent>
        </IonPage>
    );
};

export default Discover;
