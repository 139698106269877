import {FileType} from "./FileType.enum.model";
import {AttachmentStatusEnumModel} from "./AttachmentStatus.enum.model";

export enum EventType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
}

export interface PulseAttachmentViewModel {
    pulseAttachmentId: number,
    pulseId: number,
    fileType: FileType,
    fileName: string,
    s3Location: string,
    postedBy: number,
    postedDateTime: string,
    fileSize: number,
    attachmentStatus: AttachmentStatusEnumModel
}

export interface PulseViewModel {
    pulseId: number,
    eventId: number,
    eventType: EventType;
    pulseName: string,
    message: string,
    creationDate: string,
    postDate: string,
    announcerId: number,
    announcerFirstName: string,
    announcerLastName: string,
    pulseCategory: string,
    attachments: PulseAttachmentViewModel,
}
