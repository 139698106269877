import {IonHeader, IonTitle, IonToolbar} from "@ionic/react";
import React from "react";


export function AuthHeader() {
    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle>Event Pulse</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}
